var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"app-sidebar",class:{
      'app-sidebar-transparent': _vm.appOptions.appSidebarTransparent,
      'app-sidebar-grid': _vm.appOptions.appSidebarGrid
    },attrs:{"id":"sidebar"}},[_c('vue-custom-scrollbar',{staticClass:"app-sidebar-content",class:{ 'h-100 ps': _vm.appOptions.appSidebarFixed }},[_c('sidebar-nav',{ref:"sidebarNav",attrs:{"scrollTop":_vm.scrollTop},on:{"show-float-submenu":_vm.handleShowFloatSubmenu,"hide-float-submenu":_vm.handleHideFloatSubmenu}})],1)],1),_c('div',{staticClass:"app-sidebar-bg"}),_c('div',{staticClass:"app-sidebar-mobile-backdrop"},[_c('a',{staticClass:"stretched-link",attrs:{"href":"#"},on:{"click":_vm.dismissSidebarMobile}})]),(this.appOptions.appSidebarMinified)?_c('div',{staticClass:"app-sidebar-float-submenu-container",class:{
      'd-none': !this.floatSubmenu
    },style:({
      top: this.floatSubmenuTop,
      left: this.floatSubmenuLeft,
      bottom: this.floatSubmenuBottom,
      right: this.floatSubmenuRight
    }),on:{"mouseover":_vm.clearHideFloatSubmenu,"mouseleave":_vm.handleHideFloatSubmenu}},[_c('div',{staticClass:"app-sidebar-float-submenu-arrow",style:({
        top: this.floatSubmenuArrowTop,
        bottom: this.floatSubmenuArrowBottom
      })}),_c('div',{staticClass:"app-sidebar-float-submenu-line",style:({
        top: this.floatSubmenuLineTop,
        bottom: this.floatSubmenuLineBottom
      })}),_c('div',{staticClass:"app-sidebar-float-submenu"},[_vm._l((this.floatSubmenuMenu),function(menu){return [_c('sidebar-nav-list',{key:menu.path,ref:"sidebarNavList",refInFor:true,attrs:{"menu":menu,"status":menu.status},on:{"calc-float-submenu":_vm.handleCalcFloatSubmenu}})]})],2)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }