var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.menu.children)?_c('div',{staticClass:"menu-item has-sub",class:{
    active: _vm.subIsActive(_vm.menu.path),
    expand: this.stat == 'expand',
    'd-none': this.stat == 'hide'
  }},[(_vm.permissoes.find(p => parseInt(p) === parseInt(_vm.menu.role)))?_c('a',{staticClass:"menu-link",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.expand($event)},"mouseover":function($event){return _vm.mouseOver(_vm.menu.children, $event)},"mouseleave":_vm.hideFloatSubmenu}},[(_vm.menu.icon)?_c('div',{staticClass:"menu-icon"},[_c('i',{class:_vm.menu.icon})]):_vm._e(),(_vm.menu.img)?_c('div',{staticClass:"menu-icon-img"},[_c('img',{attrs:{"src":_vm.menu.img,"alt":""}})]):_vm._e(),_c('div',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.menu.title)+" "),(_vm.menu.label)?_c('span',{staticClass:"menu-label"},[_vm._v(_vm._s(_vm.menu.label))]):_vm._e(),(_vm.menu.highlight)?_c('i',{staticClass:"fa fa-paper-plane text-theme"}):_vm._e()]),(_vm.menu.badge)?_c('div',{staticClass:"menu-badge"},[_vm._v(_vm._s(_vm.menu.badge))]):_c('div',{staticClass:"menu-caret"})]):_vm._e(),_c('div',{staticClass:"menu-submenu",class:{
      'd-block': this.stat == 'expand',
      'd-none': this.stat == 'collapse'
    },style:({
      marginTop: _vm.appOptions.appSidebarMinified ? -(_vm.scrollTop + 40) + 'px' : ''
    })},[_vm._l((_vm.menu.children),function(submenu){return [(
          _vm.funcionalidades.find(
            p => parseInt(p) === parseInt(submenu.funcionalidade)
          )
        )?_c('sidebar-nav-list',{key:submenu.path,ref:"sidebarNavList",refInFor:true,attrs:{"menu":submenu},on:{"calc-float-submenu":_vm.handleCalcFloatSubmenu,"collapse-other":function($event){return _vm.handleCollapseOther(submenu)}}}):_vm._e()]})],2)]):_c('router-link',{staticClass:"menu-item",class:{ 'd-none': this.stat == 'hide' },attrs:{"to":_vm.menu.path,"active-class":"active","tag":"div"},nativeOn:{"click":function($event){return _vm.collapseOther()}}},[_c('a',{staticClass:"menu-link"},[(_vm.menu.icon)?_c('div',{staticClass:"menu-icon"},[_c('i',{class:_vm.menu.icon})]):_vm._e(),(_vm.menu.img)?_c('div',{staticClass:"menu-icon-img"},[_c('img',{attrs:{"src":_vm.menu.img,"alt":""}})]):_vm._e(),_c('div',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.menu.title)+" "),(_vm.menu.label)?_c('span',{staticClass:"menu-label"},[_vm._v(_vm._s(_vm.menu.label))]):_vm._e(),(_vm.menu.highlight)?_c('i',{staticClass:"fa fa-paper-plane text-theme"}):_vm._e()]),(_vm.menu.badge)?_c('div',{staticClass:"menu-badge"},[_vm._v(_vm._s(_vm.menu.badge))]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }