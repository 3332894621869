<template>
  <div>
    <!-- BEGIN #header -->
    <div
      id="header"
      class="app-header"
      v-bind:class="{ 'app-header-inverse': appOptions.appHeaderInverse }"
    >
      <!-- BEGIN navbar-header -->
      <div class="navbar-header">
        <button
          type="button"
          class="navbar-mobile-toggler"
          v-on:click="toggleSidebarEndMobile"
          v-if="appOptions.appSidebarTwo"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <router-link to="/app/dashboard" class="navbar-brand">
          <img class="mw-110 mh-130" :src="LogoMultiBranco" alt="" />

          <div class="align-self-sm-start p-1"><b>Multi</b>Impressões</div>
        </router-link>
        <button
          type="button"
          class="navbar-mobile-toggler"
          v-on:click="toggleTopMenuMobile"
          v-if="appOptions.appTopMenu && !appOptions.appSidebarNone"
        >
          <span class="fa-stack fa-lg text-inverse">
            <i class="far fa-square fa-stack-2x"></i>
            <i class="fa fa-cog fa-stack-1x"></i>
          </span>
        </button>
        <button
          type="button"
          class="navbar-mobile-toggler"
          v-on:click="toggleTopMenuMobile"
          v-if="appOptions.appTopMenu && appOptions.appSidebarNone"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <button
          type="button"
          class="navbar-mobile-toggler"
          v-on:click="toggleHeaderMegaMenuMobile"
          v-if="appOptions.appHeaderMegaMenu"
        >
          <span class="fa-stack fa-lg text-inverse m-t-2">
            <i class="far fa-square fa-stack-2x"></i>
            <i class="fa fa-cog fa-stack-1x"></i>
          </span>
        </button>
        <button
          type="button"
          class="navbar-mobile-toggler"
          v-on:click="toggleSidebarMobile"
          v-if="!appOptions.appSidebarNone"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
      </div>
      <!-- END navbar-header -->

      <!-- BEGIN header-nav -->
      <div class="navbar-nav">
        <header-mega-menu
          v-if="appOptions.appHeaderMegaMenu"
        ></header-mega-menu>

        <b-nav-item-dropdown
          class="navbar-item"
          toggle-class="navbar-link dropdown-toggle"
          no-caret
          v-if="appOptions.appHeaderLanguageBar"
        >
          <template slot="button-content">
            <span class="flag-icon flag-icon-us me-1" title="us"></span>
            <span class="name d-none d-sm-inline me-1">EN</span>
            <b class="caret"></b>
          </template>
          <b-dropdown-item href="javascript:;"
            ><span class="flag-icon flag-icon-us" title="us"></span>
            English</b-dropdown-item
          >
          <b-dropdown-item href="javascript:;"
            ><span class="flag-icon flag-icon-cn" title="cn"></span>
            Chinese</b-dropdown-item
          >
          <b-dropdown-item href="javascript:;"
            ><span class="flag-icon flag-icon-jp" title="jp"></span>
            Japanese</b-dropdown-item
          >
          <b-dropdown-item href="javascript:;"
            ><span class="flag-icon flag-icon-be" title="be"></span>
            Belgium</b-dropdown-item
          >
          <b-dropdown-divider class="m-b-0"></b-dropdown-divider>
          <b-dropdown-item href="javascript:;" class="text-center"
            >more options</b-dropdown-item
          >
        </b-nav-item-dropdown>
        <b-nav-item-dropdown
          right
          menu-class="me-1"
          class="navbar-item navbar-user dropdown"
          toggle-class="navbar-link dropdown-toggle d-flex align-items-center"
          no-caret
        >
          <template slot="button-content">
            <i class="fa fa-user margin-right-15"></i>
            <span class="d-none d-md-inline">{{ user.nome }}</span>
            <b class="caret"></b>
          </template>
          <a href="javascript:;" class="dropdown-item">Alterar Senha</a>
          <div class="dropdown-divider"></div>
          <a @click="logOut" class="dropdown-item">Log Out</a>
        </b-nav-item-dropdown>
      </div>
      <!-- end header navigation right -->
    </div>
    <!-- end #header -->
  </div>
</template>

<script>
import AppOptions from '../../config/AppOptions.vue'
import HeaderMegaMenu from './HeaderMegaMenu.vue'
import LogoMultiBranco from '../../assets/Logo-Multi-Branco.png'
import { getCurrentUser, setCurrentUser } from '../../utils'
import axios from 'axios'

export default {
  name: 'Header',
  components: {
    HeaderMegaMenu
  },
  data() {
    return {
      appOptions: AppOptions,
      LogoMultiBranco,
      user: {
        nome: ''
      }
    }
  },
  methods: {
    async logOut() {
      await axios
        .post('auth/logout')
        .then(res => {
          return res.data
        })
        .then(res => {
          console.log(res.mensagem)
          this.$notify({
            group: 'foo',
            type: 'success',
            title: 'Log Out efetuado com sucesso!',
            text: `${res.mensagem}`
          })
          this.$router.push({ name: 'login' })
        })
        .catch(res => {
          console.log(res)
          setCurrentUser({})
        })
    },
    toggleSidebarMobile() {
      this.appOptions.appSidebarMobileToggled =
        !this.appOptions.appSidebarMobileToggled
    },
    toggleSidebarEnd() {
      this.appOptions.appSidebarEndToggled =
        !this.appOptions.appSidebarEndToggled
    },
    toggleSidebarEndMobile() {
      this.appOptions.appSidebarEndMobileToggled =
        !this.appOptions.appSidebarEndMobileToggled
    },
    toggleTopMenuMobile() {
      this.appOptions.appTopMenuMobileToggled =
        !this.appOptions.appTopMenuMobileToggled
    },
    toggleHeaderMegaMenuMobile() {
      this.appOptions.appHeaderMegaMenuMobileToggled =
        !this.appOptions.appHeaderMegaMenuMobileToggled
    },
    checkForm: function (e) {
      e.preventDefault()
      this.$router.push({ path: '/extra/search' })
    }
  },
  mounted() {
    this.user = getCurrentUser()
  }
}
</script>
<style scoped>
.margin-right-15 {
  margin-right: 15px;
}
</style>
