import axios from 'axios'

import { apiUrl } from './constants/config'
import { getCurrentUser, setCurrentUser } from './utils'

axios.defaults.baseURL = apiUrl

axios.interceptors.request.use(
  function (config) {
    let currentUser = getCurrentUser()
    const token = currentUser !== null ? currentUser.token : null
    if (token) {
      config.headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    }

    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  function (response) {
    if (response?.data?.resultados?.usuario) {
      // Verifique se é necessário redirecionar o usuário para a página de alteração de senha.
      // if (user.alterar_senha === 1 && window.location.pathname === '/login') {
      //  window.location.href = "/alterar-senha";
      // }
    }

    return response
  },
  function (error) {
    if (
      error?.response.data.dados.error === 'Token expirado.' ||
      error?.response.data.dados.error ===
        'Ocorreu um erro ao decodificar o token.' ||
      error?.response.data.dados.error === 'Token requerido.' ||
      error?.response.data.dados.error === 'Token inválido.'
    ) {
      console.log(error?.response.data)
      setCurrentUser(null)
      // Verifique se é um ambiente de navegador e redirecione para a página de login.
      if (
        typeof window !== 'undefined' &&
        window.location.pathname !== '/login'
      ) {
        window.location.href = '/login'
      }
    }
    if (error?.response?.status === 403) {
      // Verifique se é um ambiente de navegador e redirecione para a página de acesso não autorizado.
      if (
        typeof window !== 'undefined' &&
        window.location.pathname !== '/login'
      ) {
        window.location.href = '/unauthorized'
      }
    }

    return Promise.reject(error)
  }
)

export default axios
