import Vue from 'vue'
// import routes from './config/AppRoutes'

// plugins
import VueBootstrap from 'bootstrap-vue'
import router from "./router";
import VueNVD3 from 'vue-nvd3'
import VueInsProgressBar from 'vue-ins-progress-bar'
import VueEventCalendar from 'vue-event-calendar'
import VueSparkline from 'vue-sparklines'
import * as VueGoogleMaps from 'vue2-google-maps'
import Vueditor from '@agametov/vueditor'
import VueHljs from 'vue-hljs'
import hljs from "highlight.js";
import VueSweetalert2 from 'vue-sweetalert2'
import VueNotification from 'vue-notification'
import VuePanel from './plugins/panel/'
import VueDateTimePicker from 'vue-bootstrap-datetimepicker'
import VueSelect from 'vue-select'
import VueDatepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js"
import VueMaskedInput from 'vue-maskedinput'
import VueInputTag from 'vue-input-tag'
import VueSlider from 'vue-slider-component'
import VueGoodTable from 'vue-good-table'
import VueCountdown from '@chenfengyuan/vue-countdown'
import VueColorpicker from 'vue-pop-colorpicker'
import VueCustomScrollbar from 'vue-custom-scrollbar'
import VueApexCharts from 'vue-apexcharts'
import DateRangePicker from 'vue2-daterange-picker'
import BSpinner from './components/Bootstrap/BSpinner.vue'
import moment from "moment";
import Switches from 'vue-switches';


// plugins css
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'nvd3/build/nv.d3.min.css'
import 'vue-event-calendar/dist/style.css'
import 'vue-hljs/dist/style.css'
import '@agametov/vueditor/dist/style/vueditor.min.css'
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'
import 'simple-line-icons/css/simple-line-icons.css'
import 'flag-icon-css/css/flag-icons.min.css'
import 'ionicons/dist/css/ionicons.min.css'
import 'vue-good-table/dist/vue-good-table.css'
import 'vue-select/dist/vue-select.css'
import 'vue-slider-component/theme/antd.css'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'vue-custom-scrollbar/dist/vueScrollbar.css'
import Notifications from 'vue-notification'
// color admin css
import './scss/vue.scss'
import 'bootstrap-social/bootstrap-social.css'

import App from './App.vue'

Vue.config.productionTip = false
Vue.use(BSpinner)
Vue.use(VueBootstrap)
Vue.use(VueNVD3)
Vue.use(VueEventCalendar, {locale: 'en'})
Vue.use(VueSparkline)
Vue.use(Vueditor)
Vue.use(VueHljs, { hljs })
Vue.use(VueSweetalert2)
Vue.use(VueNotification)
Vue.use(VuePanel)
Vue.use(VueDateTimePicker)
Vue.use(VueGoodTable)
Vue.use(VueColorpicker)
Vue.use(Notifications)
Vue.use(VueGoogleMaps, {
  load: {
    key: '',
    libraries: 'places'
  }
})
Vue.use(VueInsProgressBar, {
  position: 'fixed',
  show: true,
  height: '3px'
})
Vue.filter('filterDate', function (date, format = 'DD/MM/yyyy') {
  if (date) {
    return moment(date).format(format);
  } else {
    return ''
  }
});

Vue.filter('DocumentPersonBr', (documentId, documentType) => {
  documentId = typeof documentId != 'string' ? documentId.toString() : documentId;
  if (documentType === 'J') {
    documentId = documentId.padStart(14, '0');
    documentId = documentId.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  } else {
    documentId = documentId.padStart(11, '0');
    documentId = documentId.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }
  return documentId;
});


Vue.filter('filterDateTime', function (date, format = 'DD/MM/yyyy HH:mm:ss') {
  return moment(date).format(format);
});

Vue.filter('filterDateTimeUtc', function (date, format = 'DD/MM/yyyy HH:mm:ss') {
  if (date) {
    return moment(date).utc().local().format(format);
  } else {
    return ''
  }
});

Vue.filter('filterToMoney', function (value) {
  if (value === null) return ''
  let money = String(value);
  money = money.replace(',', '');
  let formatter = new Intl.NumberFormat('pt-br', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2
  });
  return formatter.format(parseFloat(money));
});
Vue.component('v-select', VueSelect);
Vue.component('datepicker', VueDatepicker)
Vue.component('masked-input', VueMaskedInput)
Vue.component('input-tag', VueInputTag)
Vue.component('vue-slider', VueSlider)
Vue.component('vue-custom-scrollbar', VueCustomScrollbar)
Vue.component('apexchart', VueApexCharts)
Vue.component('date-range-picker', DateRangePicker)
Vue.component('switches', Switches)
Vue.component(VueCountdown.name, VueCountdown);
import axios from './axios'
// const router = new VueRouter({
//     mode: 'history',
// 	routes
// })

new Vue({
  render: h => h(App),
  router,
  axios
}).$mount('#app')