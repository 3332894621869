<template>
  <div class="spinner-border" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</template>

<script>
export default {
  props:{
    variant:{
      default: '',
      type: String
    }
  },

}
</script>